import React from 'react';
import styled, { css } from 'styled-components';

import MaxWidth from 'layouts/max-width';
import CustomForm from './forms/CustomForm';
import FormTypeForm from './forms/Typeform';
import { stripHash } from 'libs/content';
import LazyImage from 'components/LazyImage';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import MediumWidth from 'layouts/medium-width';

const Wrapper = styled.div`
	iframe {
		width: 100%;
		min-height: ${p =>
			(!!p.$desktopHeight && `${p.$desktopHeight}px`) || '520px'};
		border: solid 1px ${p => p.theme.colors.grey200};
		outline: none;
		border-radius: ${p => p.theme.utils.borderRadius};
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				min-height: ${p =>
					(!!p.$mobileHeight && `${p.$mobileHeight}px`) ||
					'520px'} !important;
			`)}
	}

	.component__form--form .medium-width {
		padding: 0;
	}

	${p =>
		p.$centered === 'true' &&
		css`
			text-align: center !important;
		`};

	${p =>
		p.$hasImage &&
		css`
			display: flex;
			padding: 0;
			vertical-align: middle;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 0;
			text-align: left;
		`};
`;

const Form = styled.div`
	flex: 1 0 58%;
	${p =>
		p.theme.media.mediumDown(css`
			flex: 1 1 100%;
		`)}
`;

const Image = styled.div`
	flex: 1 0 42%;
	position: relative;
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: 40px;
			flex: 1 1 100%;
			position: relative;
		`)}
	.lazy-image {
		border-radius: 0px ${p => p.theme.utils.borderRadius}
			${p => p.theme.utils.borderRadius} 0;
		${p =>
			p.theme.media.mediumDown(css`
				border-radius: ${p => p.theme.utils.borderRadius};
			`)}
		${p =>
			p.theme.media.large(css`
				position: absolute;
				height: 100%;
			`)}

		img {
			border-radius: 0px ${p => p.theme.utils.borderRadius}
				${p => p.theme.utils.borderRadius} 0;
			width: 100%;
			height: 100%;
			${p =>
				p.theme.media.mediumDown(css`
					border-radius: ${p => p.theme.utils.borderRadius};
				`)}
		}
	}
`;

/**
 * Represents a form
 * @param {...object} props - The properties for the form
 */
export default function InlineForm({ ...props }) {
	if (!props?.segmentForm && !props?.typeformUrl) return;

	// Return without wrappers if is set to not have wrappers and has no image and should be in a popup
	if (props?.isnospacing === 'true' || props?.aspopup === 'true') {
		return (
			<Wrapper
				id={props?.formId && stripHash(props?.formId)}
				className="component__form"
				data-cy="component__form"
				$centered={props?.centered}
				$hasImage={!!props?.image}
				$desktopHeight={props?.formHeights?.desktop}
				$mobileHeight={props?.formHeights?.mobile}>
				{(props?.segmentForm && (
					<CustomForm form={props?.segmentForm} {...props} />
				)) ||
					(props?.typeformUrl && (
						<FormTypeForm form={props?.typeformUrl} {...props} />
					))}
			</Wrapper>
		);
	}

	// Set the width wrapper based on if it has an image
	const WidthWrapper =
		props?.image?.file?.url || props?.typeformUrl ? MaxWidth : MediumWidth;

	return (
		<Spacing
			{...props}
			className="component__form"
			data-cy="component__form"
			id={props?.formId && stripHash(props?.formId)}>
			<WidthWrapper
				className={
					((props?.image?.file?.url || props?.typeformUrl) &&
						'max-width') ||
					'medium-width'
				}>
				{props?.hidetitle !== 'true' && (
					<TitleAndText
						title={props?.title}
						text={props?.intro}
						headinglevel={props?.headinglevel}
						settings={props?.settings}
						nested={true}
					/>
				)}
				<Wrapper
					id={props?.formId && stripHash(props?.formId)}
					index={props?.index}
					$hasImage={!!props?.image}
					$desktopHeight={props?.formHeights?.desktop}
					$mobileHeight={props?.formHeights?.mobile}>
					<Form className="component__form--form">
						{(props?.segmentForm && (
							<CustomForm form={props?.segmentForm} {...props} />
						)) ||
							(props?.typeformUrl && (
								<FormTypeForm
									form={props?.typeformUrl}
									{...props}
								/>
							))}
					</Form>
					{props?.image?.file?.url && (
						<Image>
							<LazyImage {...props?.image} fit="fill" />
						</Image>
					)}
				</Wrapper>
			</WidthWrapper>
		</Spacing>
	);
}
