import React from 'react';

import NaeringsserviceLead from 'components/forms/custom-forms/NaeringsserviceLead';
import ConsentForm from 'components/forms/custom-forms/ConsentForm';

/**
 * Represents a custom form (with tracking to Segment)
 * @param {...object} props - The properties for the form
 */
export default function Segment({ ...props }) {
	if (!props?.form) return;

	return (
		<div>
			{props?.form === 'Næringsservice - Kontaktskjema' ? (
				<NaeringsserviceLead {...props} centeredThankYou={true} />
			) : [
					'Samtykke til elektronisk markedsføring',
					'Meld deg på nyhetsbrev fra NTE',
					'Strømvarselet',
			  ].includes(props?.form) ? (
				<ConsentForm
					subscription={
						props?.form === 'Strømvarselet'
							? 'Strømvarselet'
							: 'Markedsføring'
					}
					{...props}
					centeredThankYou={true}
				/>
			) : props?.form === 'E-mail Capture (konkurranse)' ? (
				<ConsentForm
					event="Email Optin"
					submitTexts={{
						submitting: 'Lagrer',
						submit: 'Ja, jeg vil delta',
						submitted:
							'Flott, vi har sendt deg en e-post hvor du må bekrefte at e-postadressen tilhører deg. Har du ikke mottatt e-post, husk å sjekke søppelpost.',
					}}
					{...props}
				/>
			) : null}
		</div>
	);
}
