import React from 'react';
import styled, { css } from 'styled-components';

import { usePopup } from 'context/PopupProvider';
import { getComponentSettings } from 'libs/content';
import ImageAndContent from 'parts/image-and-content/ImageAndContent';
import MaxWidth from 'layouts/max-width';
import Button from 'components/forms/Button';
import Popup from 'components/popup/Popup';
import CustomForm from './forms/CustomForm';
import TypeForm from './forms/Typeform';
import InlineForm from './InlineForm';
import SanitizeHtml from 'libs/SanitizeHtml';
import { isNoSpacing } from 'layouts/Spacing';

const Wrapper = styled.div`
	iframe {
		width: 100%;
		min-height: ${p =>
			p.$desktopHeight ? `${p.$desktopHeight}px` : '520px'};
		border: solid 1px ${p => p.theme.colors.grey200};
		outline: none;
		border-radius: ${p => p.theme.utils.borderRadius};
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				min-height: ${p =>
					p.$mobileHeight
						? `${p.$mobileHeight}px`
						: '520px'} !important;
			`)}
	}
`;

/**
 * Represents a form
 * @param {string} headinglevel - The heading level for the title of the form
 * @param {object} style - The styling attribute
 * @param {string} hiddenFields - The hidden values for the iframe
 * @param {...object} props - The rest of the properties for the form
 */
export default function Form({
	headinglevel = 'h2',
	style,
	hiddenFields,
	...props
}) {
	// If no ID set then generate one and add as props
	if (!props?.formId) {
		props.formId = `form-${Math.floor(Math.random() * 100)}`;
	}

	const componentSettings = {
		headinglevel,
		hiddenFields,
		isnospacing: isNoSpacing(props?.spacing) ? 'true' : 'false',
		formHeights: {
			desktop: props?.formHeightDesktop || 750,
			mobile: props?.formHeightMobile || 520,
		},
		...props,
		...getComponentSettings({ settings: props?.settings, props }),
	};

	// When form is set to be inline, not inside a popup
	if (componentSettings?.aspopup !== 'true') {
		return <InlineForm {...componentSettings} />;
	}

	// When form is set to be inside a popup and no image is set
	if (componentSettings?.isnospacing === 'true' || !props?.image) {
		return (
			<MaxWidth
				className="component__form max-width"
				data-cy="component__form">
				<Wrapper
					id={props?.formId}
					$desktopHeight={componentSettings?.formHeights.desktop}
					$mobileHeight={componentSettings?.formHeights.mobile}>
					<PopupContent {...componentSettings} />
				</Wrapper>
			</MaxWidth>
		);
	}

	// When form is set to be inside a popup and has an image use ImageAndContent
	return (
		<Wrapper
			id={props?.formId}
			className="component__form"
			data-cy="component__form"
			$desktopHeight={componentSettings?.formHeights.desktop}
			$mobileHeight={componentSettings?.formHeights.mobile}>
			<ImageAndContent
				title={props?.title}
				imageposition="Halvdel venstre"
				headinglevel="h2"
				imagecrop={props?.imagecrop || 'Beskjær til 1:1'}
				image={{ ...props?.image, width: 768, height: 768 }}
				spacing={props?.spacing}
				component={
					<>
						{props?.intro?.childMarkdownRemark?.html && (
							<SanitizeHtml html={props?.intro} />
						)}
						<PopupContent {...componentSettings} />
					</>
				}
			/>
		</Wrapper>
	);
}

/**
 * Represents the content in the pop up and trigger-button
 * @param {...object} props - The properties for the pop up
 */
export function PopupContent({ style, ...props }) {
	const { activePopup, setActivePopup } = usePopup();

	// If there are buttons, use the first one as the trigger button
	const button = props?.buttons?.length && props?.buttons[0];

	// If no formId is set, generate one
	const formId = props?.formId || props?.contentful_id;

	return (
		<>
			<Button
				{...button}
				onClick={() => {
					setActivePopup(formId);
				}}
				className={`${props?.className || ''} button-popup`}
				aria-haspopup={true}
				aria-controls={formId}
				aria-expanded={activePopup === formId}
				tracking={false}
				style={style}>
				{button?.text || button?.tittel || props?.title}
			</Button>

			<Popup
				id={formId}
				title={button?.text || 'Åpne skjema'}
				size={props?.layout || 'medium'}
				closeButton={props?.whiteicon === 'true' ? 'white' : 'black'}
				padding={
					!!props?.segmentForm
						? 'true'
						: props?.hidetitle !== 'true'
						? 'true'
						: 'false'
				}>
				{(props?.segmentForm && (
					<CustomForm form={props?.segmentForm} {...props} />
				)) ||
					(props?.typeformUrl && (
						<TypeForm form={props?.typeformUrl} {...props} />
					))}
			</Popup>
		</>
	);
}
